export default {
  'VideoWidget': () => import('./Video.vue'),
  'ZoomImage': () => import('./ZoomImage.vue'),
  'GalleryMedium': () => import('./GalleryMedium.vue'),
  'GallerySmall': () => import('./GallerySmall.vue'),
  'GalleryBuilder': () => import('./GalleryBuilder.vue'),
  'ModalDefault': () => import('./modals/ModalDefault'),
  'ModalSuccess': () => import('./modals/ModalSuccess'),
  'ModalSocial': () => import('./modals/ModalSocial'),
  'ButtonModal': () => import('./buttons/ButtonModal'),
  'CartItemsCount': () => import('./data/CartItemsCount'),
  'ButtonCartAddItem': () => import('./buttons/ButtonCartAddItem'),
  'MapObjects': () => import('./MapObjects'),
  'ContFixed': () => import('./ContFixed'),
  'VistavkaBunner': () => import('./vistavka/VistavkaBunner'),

  'Offer': () => import('./Form/Offer'),
  'ManagerItem': () => import('./Form/ManagerItem'),
  'FormPhone': () => import('./Form/FormPhone'),
  'FormDate': () => import('./Form/FormDate'),
  'Ipoteka': () => import('./Form/Ipoteka'),
  'IpotekaCalc': () => import('./Form/IpotekaCalc'),
  'IpotekaCalcLight': () => import('./Form/IpotekaCalcLight'),

  'MapHouses': () => import('./MapHouses'),
  'ArchChart': () => import('./ArchChart'),
  'StYearChart': () => import('./StYearChart'),
  'BrandChart': () => import('./BrandChart'),
  'MetrikaChart': () => import('./MetrikaChart'),
  'AgeChart': () => import('./AgeChart'),
  'Chart': () => import('./Chart'),
  'Subscribe': () => import('./modals/Subscribe'),
  'Badge': () => import('./badges/Badge'),
}
